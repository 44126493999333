import IDEE$control$PredefinedZoomControl from './facade/js/predefinedzoomcontrol';
import IDEE$control$ViewHistoryControl from './facade/js/viewhistorycontrol';
import IDEE$plugin$ViewManagement from './facade/js/viewmanagement';
import IDEE$control$ViewManagementControl from './facade/js/viewmanagementcontrol';
import IDEE$control$ZoomExtentControl from './facade/js/zoomextentcontrol';
import IDEE$control$ZoomPanelControl from './facade/js/zoompanelcontrol';
import IDEE$impl$control$PredefinedZoomControl from './impl/ol/js/predefinedzoomcontrol';
import IDEE$impl$control$ViewHistoryControl from './impl/ol/js/viewhistorycontrol';
import IDEE$impl$control$ViewManagementControl from './impl/ol/js/viewmanagement';
import IDEE$impl$control$ZoomExtentControl from './impl/ol/js/zoomextentcontrol';
import IDEE$impl$control$ZoomPanelControl from './impl/ol/js/zoompanelcontrol';

if (!window.IDEE.control) window.IDEE.control = {};
if (!window.IDEE.plugin) window.IDEE.plugin = {};
if (!window.IDEE.impl) window.IDEE.impl = {};
if (!window.IDEE.impl.control) window.IDEE.impl.control = {};
window.IDEE.control.PredefinedZoomControl = IDEE$control$PredefinedZoomControl;
window.IDEE.control.ViewHistoryControl = IDEE$control$ViewHistoryControl;
window.IDEE.plugin.ViewManagement = IDEE$plugin$ViewManagement;
window.IDEE.control.ViewManagementControl = IDEE$control$ViewManagementControl;
window.IDEE.control.ZoomExtentControl = IDEE$control$ZoomExtentControl;
window.IDEE.control.ZoomPanelControl = IDEE$control$ZoomPanelControl;
window.IDEE.impl.control.PredefinedZoomControl = IDEE$impl$control$PredefinedZoomControl;
window.IDEE.impl.control.ViewHistoryControl = IDEE$impl$control$ViewHistoryControl;
window.IDEE.impl.control.ViewManagementControl = IDEE$impl$control$ViewManagementControl;
window.IDEE.impl.control.ZoomExtentControl = IDEE$impl$control$ZoomExtentControl;
window.IDEE.impl.control.ZoomPanelControl = IDEE$impl$control$ZoomPanelControl;
